.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    display: flex !important;
    margin: 0px !important;
    padding: 10px !important;
}
h2 {
    color: #666;
    font-size: 22px;
}
table.fc-col-header {
	width: 100% !important;
}